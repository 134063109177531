// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-dl-js": () => import("./../../../src/pages/dl.js" /* webpackChunkName: "component---src-pages-dl-js" */),
  "component---src-pages-dmca-policy-js": () => import("./../../../src/pages/dmca-policy.js" /* webpackChunkName: "component---src-pages-dmca-policy-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-features-instant-push-js": () => import("./../../../src/pages/features/instant-push.js" /* webpackChunkName: "component---src-pages-features-instant-push-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-getting-to-know-our-add-ons-js": () => import("./../../../src/pages/getting-to-know-our-add-ons.js" /* webpackChunkName: "component---src-pages-getting-to-know-our-add-ons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-1-js": () => import("./../../../src/pages/la1.js" /* webpackChunkName: "component---src-pages-la-1-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-press-kit-js": () => import("./../../../src/pages/press-kit.js" /* webpackChunkName: "component---src-pages-press-kit-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-old-js": () => import("./../../../src/pages/privacy-old.js" /* webpackChunkName: "component---src-pages-privacy-old-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-tos-old-js": () => import("./../../../src/pages/tos-old.js" /* webpackChunkName: "component---src-pages-tos-old-js" */)
}

